<template>
  <div class="container">
    <BaseTitle v-if="title" class="grid-title">{{ title }}</BaseTitle>
    <template v-if="brochures.length">
      <div class="brochures-grid">
        <template v-for="(brochure, index) in brochures" :key="brochure.id">
          <BrochureItem
            :brochure="brochure"
            class="brochure-item"
            :impression-source="impressionSource"
          />

          <!-- Google in feed ad every 6th brochure -->
          <AdvertisementGoogleInFeed
            v-if="(index + 1) % 6 === 0"
            :ad-index="(index + 1) / 6 - 1"
            :display-at="InFeedAdDisplayAt.BrochuresGrid"
          />
        </template>
      </div>
      <CenteredContent v-if="showLoadMoreButton" class="mt-3 mb-3">
        <BaseButton @click="loadMore">{{ $t("new.load-more") }}</BaseButton>
      </CenteredContent>
    </template>
    <BaseText v-else>
      {{ $t("new.no-folders") }}
    </BaseText>
  </div>
</template>

<script setup lang="ts">
import { useBrochuresQuery } from "~/graphql/generated";
import type {
  BrochureImpressionSource,
  BrochuresInput,
  PaginationInput,
} from "~/graphql/generated";
import { InFeedAdDisplayAt } from "~/types";

const props = defineProps<{
  title?: string;
  input: BrochuresInput;
  showLoadMore?: boolean;
  pagination: PaginationInput;
  impressionSource: BrochureImpressionSource;
}>();

// Get the users location (if set)
const location = useStatefulCookie<{ lat: number; long: number } | null>(
  "location"
);

const pagination = toReactive(props.pagination);

// Mark this as reactive, so the query will re-run when the value changes
const variables = computed(() => {
  return {
    brochuresInput: {
      ...props.input,
      ...(location.value &&
        !props.input.citySlug && {
          point: {
            latitude: location.value.lat,
            longitude: location.value.long,
          },
        }),
    },
    pagination,
    limitPlusOffset: pagination.limit + pagination.offset,
  };
});

const { data } = await useBrochuresQuery({
  variables,
});

const brochures = ref(data.value?.brochures ?? []);

const showLoadMoreButton = computed(
  () =>
    props.showLoadMore &&
    data.value?.nextBrochure &&
    data.value.nextBrochure.length > 0
);
// When the data changes, check if we need to append or replace the data (in case of the load more button press)
watch(
  data,
  () => {
    if (pagination.offset === 0) {
      brochures.value = data?.value?.brochures ?? [];
    } else {
      brochures.value = brochures.value.concat(data?.value?.brochures ?? []);
    }
  },
  { immediate: true }
);

// Watch the input and reset the pagination when it changes
watch(
  () => props.input,
  () => {
    pagination.offset = 0;
  }
);

const loadMore = () => {
  pagination.offset = brochures.value.length;
};
</script>

<style lang="scss" scoped>
.brochures-grid {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-title::first-letter {
  text-transform: capitalize;
}
</style>
